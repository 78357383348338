import React, { useRef, useState } from 'react';
import { useRootStore } from '@root/components';
import { observer } from 'mobx-react-lite';
import { Button, FormControl, InputLabel, MenuItem, Paper, Select, Snackbar, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import ImageUploadView from '@root/components/image-upload/imageUpload.view';
import styled from 'styled-components';
import { AdsStore } from '@root/stores';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as Yup from 'yup';
import FileUploadView from '@root/components/file-upload/fileUpload.view';
import LoginPage from '@root/pages/login/login.page';

enum AdType {
  CARD = 'card',
  VIDEO = 'video',
}

const validationSchemaImg = Yup.object().shape({
  title: Yup.string().min(1).max(256).required('The field cannot be empty'),
  description: Yup.string().min(1).max(1000).required('The field cannot be empty'),
  buttonText: Yup.string().min(1).max(256).required('The field cannot be empty'),
  PlayingTime: Yup.string().min(1).required('The field cannot be empty'),
  //image: Yup.required('Upload the photo please')
});

const validationSchemaVideo = Yup.object().shape({
  buttonText: Yup.string().min(1).max(256).required('The field cannot be empty'),
  PlayingTime: Yup.string().min(1).required('The field cannot be empty'),
  //video: Yup.required('Upload the video please')
});

const GenerateJsonContainer = (json: string, handleSubmit: any, isValid: boolean) => (
  <GenerateWrapper>
    <Button variant="contained" fullWidth type="submit" onClick={handleSubmit} disabled={!isValid}>
      GENERATE
    </Button>
    {JsonContainer(json)}
  </GenerateWrapper>
);

const JsonContainer = (json: string) => {
  const textAreaRef = useRef(null);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const handleClose = () => {
    setSnackBarOpen(false);
  };

  return (
    <JsonWrapper>
      <TextField ref={textAreaRef} id="outlined-multiline-static" variant="outlined" multiline fullWidth value={json} />
      <CopyToClipboard text={json}>
        <Button onClick={() => setSnackBarOpen(true)}>Copy</Button>
      </CopyToClipboard>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackBarOpen}
        onClose={handleClose}
        message="Copied."
        key={'bottom right'}
      />
    </JsonWrapper>
  );
};

const videoForm = (adsStore: AdsStore, json: string) => (
  <FormWrapper>
    <Formik
      initialValues={{ buttonText: '', siteUrl: '', PlayingTime: '' }}
      validationSchema={validationSchemaVideo}
      onSubmit={(values) => {
        adsStore.submitVideo(values.buttonText, values.siteUrl, values.PlayingTime);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit }) => (
        <>
          {/*need add video upload*/}
          <PaperWrapper>
            <FileUploadView
              buttonText="Upload Video"
              onFileUploaded={(url) => {
                adsStore.setVideoUrl(url);
              }}
            />
          </PaperWrapper>
          <PaperWrapper>
            <>
              <h3>Button</h3>
              <TextField
                fullWidth
                name="buttonText"
                label="Button Text"
                onChange={handleChange}
                value={values.buttonText}
              />
              {touched.buttonText && errors.buttonText && <ValidationMessage> {errors.buttonText} </ValidationMessage>}
              <TextField fullWidth name="siteUrl" label="Site URL" onChange={handleChange} value={values.siteUrl} />
              {touched.siteUrl && errors.siteUrl && <ValidationMessage> {errors.siteUrl} </ValidationMessage>}
            </>
          </PaperWrapper>
          <PaperWrapper>
            <h3>Add playing time to skip the ads</h3>
            <TextField fullWidth name="PlayingTime" label="sec" onChange={handleChange} value={values.PlayingTime} />
            {touched.PlayingTime && errors.PlayingTime && <ValidationMessage> {errors.PlayingTime} </ValidationMessage>}
          </PaperWrapper>
          {GenerateJsonContainer(json, handleSubmit, isValid)}
        </>
      )}
    </Formik>
  </FormWrapper>
);

const PaperWrapper = ({ children }) => (
  <Paper style={{ padding: 20, marginTop: 10 }} elevation={1}>
    {children}
  </Paper>
);

const cardForm = (adsStore: AdsStore, json: string) => {
  const onPicture = (file: File) => {
    adsStore.setImage(file);
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={{
          title: '',
          description: '',
          buttonText: '',
          siteUrl: '',
          PlayingTime: '',
          imageUrl: '',
        }}
        validationSchema={validationSchemaImg}
        onSubmit={(values) => {
          adsStore.submitCard(values.title, values.description, values.buttonText, values.siteUrl, values.PlayingTime);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit }) => (
          <>
            <PaperWrapper>
              <h3>Advertisement content</h3>
              <TextField fullWidth name="title" label="Title" onChange={handleChange} value={values.title} />
              {touched.title && errors.title && <ValidationMessage> {errors.title} </ValidationMessage>}
              <TextField
                fullWidth
                name="description"
                label="Description"
                onChange={handleChange}
                value={values.description}
              />
              {touched.description && errors.description && (
                <ValidationMessage> {errors.description} </ValidationMessage>
              )}
            </PaperWrapper>
            <ImageUploadView buttonText="Image" onImage={(file) => onPicture(file)} />
            <PaperWrapper>
              <>
                <h3>Button</h3>
                <TextField
                  fullWidth
                  name="buttonText"
                  label="Button Text"
                  onChange={handleChange}
                  value={values.buttonText}
                />
                {touched.buttonText && errors.buttonText && (
                  <ValidationMessage> {errors.buttonText} </ValidationMessage>
                )}
                <TextField fullWidth name="siteUrl" label="Site URL" onChange={handleChange} value={values.siteUrl} />
                {touched.siteUrl && errors.siteUrl && <ValidationMessage> {errors.siteUrl} </ValidationMessage>}
              </>
            </PaperWrapper>
            <PaperWrapper>
              <>
                <h3>Add playing time to skip the ad</h3>
                <TextField
                  fullWidth
                  name="PlayingTime"
                  label="sec"
                  onChange={handleChange}
                  value={values.PlayingTime}
                />
                {touched.PlayingTime && errors.PlayingTime && (
                  <ValidationMessage> {errors.PlayingTime} </ValidationMessage>
                )}
              </>
            </PaperWrapper>
            {GenerateJsonContainer(json, handleSubmit, isValid)}
          </>
        )}
      </Formik>
    </FormWrapper>
  );
};

const AdsPage = observer(() => {
  const { adsStore, profileStore } = useRootStore();
  const { json } = adsStore;

  if (!localStorage.getItem('authToken')) {
    return <LoginPage />;
  }

  const onActionSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    const type = event.target.value as string;
    adsStore.setVideoTypeAd(type === AdType.VIDEO);
  };

  const SelectType = (
    <FormControl style={{ display: 'flex' }}>
      <InputLabel id="ads-action-select-label">Ad type</InputLabel>
      <Select
        labelId="ads-action-select-label"
        id="ads-action-select"
        value={adsStore.isVideoTypeAd ? AdType.VIDEO : AdType.CARD}
        onChange={(e) => onActionSelect(e)}
      >
        <MenuItem value={AdType.CARD}>Card</MenuItem>
        <MenuItem value={AdType.VIDEO}>Video</MenuItem>
      </Select>
    </FormControl>
  );

  return (
    <>
      {SelectType}
      {adsStore.isVideoTypeAd ? videoForm(adsStore, json) : cardForm(adsStore, json)}
    </>
  );
});

const FormWrapper = styled.div`
  flex-direction: column;
  padding-horizontal: 20;
`;

const GenerateWrapper = styled.div`
  flex: 1;
  padding-top: 30pt;
  padding-bottom: 20pt;
`;

const JsonWrapper = styled.div`
  flex: 1;
  padding-top: 20pt;
  padding-bottom: 20pt;
`;

const ValidationMessage = styled.div`
  color: red;
`;

export default AdsPage;
