import { LinearProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';

interface LoadingViewProps {
  title: string;
}

const LoadingViewContainer = styled.h2``;

function LoadingView({ title }: LoadingViewProps) {
  return (
    <>
      <LoadingViewContainer>{title}</LoadingViewContainer>
      <LinearProgress />
      <Skeleton />
    </>
  );
}

export default LoadingView;
