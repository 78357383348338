import React from 'react';
import styled from 'styled-components';

interface AvatarViewProps {
  src: string;
  alt?: string;
}

const AvatarViewContainer = styled.img`
  width: 100%;
  margin: 'auto';
  display: 'block';
  max-width: '100%';
  max-height: '100%';
`;

function AvatarView({ src, alt }: AvatarViewProps) {
  return <AvatarViewContainer src={src} alt={alt} />;
}

export default AvatarView;
