import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';

interface OwnProps {
  open: boolean;
  title: string;
  description: string;
  approveButtonTitle: string;
  onApproveButtonClick: () => void;
}

const PromptDialog = (props: OwnProps) => {
  const { title, description, approveButtonTitle, onApproveButtonClick } = props;
  const [open, setPromptDialogOpen] = React.useState(false);

  const handleClickOpen = () => {
    setPromptDialogOpen(true);
  };

  const handleClose = () => {
    setPromptDialogOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onApproveButtonClick()} color="primary" autoFocus>
          {approveButtonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromptDialog;
