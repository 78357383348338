import {useState, useEffect} from 'react';
import {EventTypeEnum} from '@root/models/EventTypeEnum';
import {EventsStore, FBeventsStore} from '@root/stores';

const transformFacebookEventData = (fbEventData) => {
    return {
        fbEventId: fbEventData.id.toString(),
        details: [
            {
                locale: "pl",
                title: fbEventData.name,
                description: fbEventData.description
            }
        ],
        categoryId: null,
        maxParticipantsCount: 0,
        type: 2,
        start: fbEventData.start_timestamp,
        end: fbEventData.end_timestamp,
        address: {
            address: fbEventData.address
        }
    };
};
const transformEventData = (eventData) => {
    return {
        id: eventData.id,
        details: eventData.details,
        categoryId: eventData.categoryId,
        maxParticipantsCount: eventData.maxParticipantsCount,
        type: 2,
        start: eventData.start,
        end: eventData.end,
        address: {
            address: eventData.address
        }
    };
};
export const useFetchAndTransformEvent = (eventId: string, fbEventId: string, eventsStore: EventsStore, fbEventsStore: FBeventsStore) => {
    const [editedEvent, setEditedEvent] = useState<any | null>(null);
    const [eventType, setEventType] = useState<EventTypeEnum>(EventTypeEnum.NewEvent);
    useEffect(() => {
        if (eventId) {
            setEventType(EventTypeEnum.Event);
            eventsStore.fetchDetails(eventId)
                .then(() => {
                    const transformedData = transformEventData(eventsStore.eventDetails);
                    setEditedEvent(transformedData);
                })
                .catch(error => {
                    console.error('Failed to fetch event details:', error);
                });
        } else if (fbEventId) {
            setEventType(EventTypeEnum.FacebookEvent);
            fbEventsStore.fetchDetails(fbEventId)
                .then(() => {
                    const transformedData = transformFacebookEventData(fbEventsStore.eventDetails);
                    setEditedEvent(transformedData);
                })
                .catch(error => {
                    console.error('Failed to fetch Facebook event details:', error);
                });
        } else {
            setEventType(EventTypeEnum.NewEvent);
            setEditedEvent({
                details: [
                    {
                        locale: "pl",
                        title: "",
                        description: ""
                    }],
                categoryId: 0,
                maxParticipantsCount: 0,
                type: 0,
                start: "",
                end: "",
                address: {
                    address: ""
                }
            });
        }
    }, [eventId, fbEventId, eventsStore, fbEventsStore]);

    return {editedEvent, setEditedEvent, eventType};
};

