import { IntlProvider, MessageFormatElement } from 'react-intl';

export function L10nProvider({
  locale,
  messages,
  children,
}: {
  locale: string;
  messages: Record<string, MessageFormatElement[]>;
  children: React.ReactNode;
}) {
  return (
    <IntlProvider locale={locale} defaultLocale="en" messages={messages}>
      {children}
    </IntlProvider>
  );
}
