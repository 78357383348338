import { Button } from '@material-ui/core';
import React, { useImperativeHandle, useState } from 'react';
import ImageUploader from 'react-images-upload';

interface ImageUploadViewProps {
  imageUrl?: string;
  buttonText: string;
  onImage(file: File): void;
}

const ImageUploadView = React.forwardRef(({ imageUrl, buttonText, onImage }: ImageUploadViewProps, ref) => {
  const [image, setImage] = useState(imageUrl ?? '');
  useImperativeHandle(
    ref,
    () => ({
      getImage: () => {
        return image;
      },
    }),
    [image]
  );
  const onDrop = (files: File[], pictures: string[]) => {
    if (files[0]) {
      setImage(URL.createObjectURL(files[0]));
      onImage(files[0]);
    }
  };

  if (image.length !== 0) {
    return (
      <>
        <div>
          <img src={image} style={{ height: 'auto', width: '540px' }} />
        </div>
        <Button
          onClick={() => {
            setImage('');
          }}
        >
          Clear
        </Button>
      </>
    );
  }

  return (
    <ImageUploader
      withIcon={true}
      buttonText={buttonText}
      onChange={onDrop}
      imgExtension={['.jpg', '.jpeg', '.png']}
      maxFileSize={5242880}
      fileSizeError={' file size is too big '}
      fileTypeError={' is not supported file extension '}
    />
  );
});

export default ImageUploadView;
