import React from 'react';
import { BucketStore } from '@root/stores/bucket/bucket.store';
import { useRootStore } from '@root/components';
import { observer } from 'mobx-react-lite';

interface FileUploadViewProps {
  buttonText: string;
  onFileUploaded(url: string): void;
}

const FileUploadView = observer(({ buttonText, onFileUploaded }: FileUploadViewProps) => {
  const { bucketStore } = useRootStore();

  const onDrop = async (event: any) => {
    bucketStore.setFile(event.target.files[0]);
    await bucketStore.uploadFile();
    if (bucketStore.fileUrl) {
      onFileUploaded(bucketStore.fileUrl);
    }
  };

  // const onUploadFile = async (event: any) => {
  //   await bucketStore.uploadFile();
  //   if (bucketStore.fileUrl) {
  //     onFileUploaded(bucketStore.fileUrl);
  //   }
  // };

  const onDeleteFile = async (event: any) => {
    await bucketStore.deleteFile();
  };

  return (
    <div>
      <h3>Upload a file:</h3>
      <input type="file" name="file" onChange={onDrop} />
      {/*<div>*/}
      {/*  {bucketStore.file && (*/}
      {/*    <button type="button" onClick={onUploadFile}>*/}
      {/*      {buttonText}*/}
      {/*    </button>*/}
      {/*  )}*/}
      {/*</div>*/}
      {bucketStore.fileUrl && (
        <>
          <button type="button" onClick={onDeleteFile}>
            Delete
          </button>
          <div>
            <a href={bucketStore.fileUrl} target="_blank" rel="noopener noreferrer">
              {bucketStore.fileUrl}
            </a>
          </div>
        </>
      )}
    </div>
  );
});

export default FileUploadView;
