import React from 'react';
import styled from 'styled-components';

interface MaxCounter {
  currentLength: number,
  maxLength: number
}

const MaxCounterField: React.FC<MaxCounter> = (({
  currentLength,
  maxLength
}) => {
  return (
    <Wrapper>
      <span>{currentLength}</span>
      <span>/</span>
      <span>{maxLength}</span>
    </Wrapper>
  )
})

const Wrapper = styled.div`
    display: inline-block;
    font-size: 18px;
`;

export default MaxCounterField;
