import React, { useEffect, useState } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface EditorProps {
  content: string;
  onChange: (content: string) => void;
}

const RichtextEditor = ({ content, onChange }: EditorProps) => {
  const [editorState, setEditorState] = useState<ContentState>();

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(content);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    setEditorState(EditorState.createWithContent(contentState));
  }, []);

  const handleEditorChange = (state) => {
    const htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onChange(htmlContent);
    setEditorState(state);
  };

  return (
    <div>
      <div style={{ border: '1px solid black', padding: '2px', minHeight: '400px' }}>
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
        />
      </div>
    </div>
  );
};

export default RichtextEditor;
