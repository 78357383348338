import { MessageFormatElement } from '@formatjs/icu-messageformat-parser';
import { L10nProvider } from '../l10n-provider';
import { RootStoreProvider } from '../root-store-provider';

export function RootProviders({
  locale,
  messages,
  children,
}: {
  locale: string;
  messages: Record<string, MessageFormatElement[]>;
  children: React.ReactNode;
}) {
  return (
    <RootStoreProvider>
      <L10nProvider locale={locale} messages={messages}>
        {children}
      </L10nProvider>
    </RootStoreProvider>
  );
}
