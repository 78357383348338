const environment = process.env.NODE_ENV;
let conf;

if (environment === 'production') {
    conf = {
        environment_name: 'prod',
        API_URL: 'https://api.joitin-st.ffflabel-dev.com/api',
        version: '0.0.3',
    };
} else if (environment === 'development') {
    conf = {
        environment_name: 'development',
        API_URL: 'https://api.joitin-st.ffflabel-dev.com/api',
        //API_URL: 'https://api.joitin.ffflabel-dev.com/api',
        version: '0.0.3',
    };
} else {
    conf = {
        environment_name: 'local',
        API_URL: 'http://localhost/api',
        version: '0.0.3',
    };
}

export const appConfig = conf;
